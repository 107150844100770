import {Component} from '@angular/core';

@Component({
  selector: 'app-nav-bar-desk-component',
  templateUrl: '../../Views/Page/NavBarDeskComponent.html',
  styleUrls: ['../../../assets/scss/menuDesktop.scss']
})

export class NavBarDeskComponent {
}
