export class ContactUs {
  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public country: string,
    public subdivision: string,
    public phone: string,
    public description: string
  ) {
  }
}
