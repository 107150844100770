import {Component} from '@angular/core';

@Component({
  selector: 'app-nav-bar-component',
  templateUrl: '../../Views/Page/NavBarComponent.html',
  styleUrls: ['../../../assets/scss/menu.scss']
})

export class NavBarComponent {
}

