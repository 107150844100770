import {Component} from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: '../Views/NotFoundComponent.html',
  styleUrls: ['../../assets/scss/NotFound.scss']
})

export class NotFoundComponent {
}
