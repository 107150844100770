import {Component} from '@angular/core';

@Component({
  selector: 'app-know-us-component',
  templateUrl: '../Views/KnowUsComponent.html',
  styleUrls: ['../../assets/scss/knowUs.scss']
})

export class KnowUsComponent {
}
